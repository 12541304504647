var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bg-white border"
  }, [_c('div', {
    staticClass: "w-full px-5 py-3 flex justify-between items-center mdmax:px-3"
  }, [_c('p', {
    staticClass: "font-bold text-base text-neutral-500"
  }, [_vm._v("Notifications")]), _vm.notifications.length > 0 ? _c('p', {
    staticClass: "text-yellow font-medium text-base cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.updateAllReadStatus();
      }
    }
  }, [_vm._v("Mark All as Read")]) : _vm._e()]), _c('div', {
    staticClass: "max-h-96 overflow-y-auto"
  }, [_vm.notifications.length > 0 ? _vm._l(_vm.notifications, function (data, index) {
    return _c('div', {
      key: index,
      staticClass: "border-t"
    }, [_c('div', {
      class: "".concat(data.read ? 'bg-white' : 'bg-yellow-100', " flex items-start p-5 gap-5 cursor-pointer mdmax:p-3"),
      on: {
        "click": function click($event) {
          return _vm.updateStatus(data);
        }
      }
    }, [_c('div', {
      staticClass: "w-full mdmax:text-left"
    }, [_c('p', {
      staticClass: "text-neutral-500 text-base font-semibold mb-2"
    }, [_vm._v(" " + _vm._s(data.title) + " ")]), _c('p', {
      staticClass: "text-neutral-400 text-sm"
    }, [_vm._v(" " + _vm._s(data.message) + " ")]), data.createdDate ? _c('p', {
      staticClass: "text-neutral-400 text-xs font-medium mt-2"
    }, [_vm._v(" " + _vm._s(_vm.formatDate(data.createdDate)) + " ")]) : _vm._e()])])]);
  }) : [_c('div', {
    staticClass: "border-t flex justify-center items-center py-6 text-sm text-neutral-500"
  }, [_vm._v("- Notification is empty -")])]], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }