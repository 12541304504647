<template>
  <div class="bg-white border">
    <div class="w-full px-5 py-3 flex justify-between items-center mdmax:px-3">
      <p class="font-bold text-base text-neutral-500">Notifications</p>
      <p class="text-yellow font-medium text-base cursor-pointer" v-if="notifications.length > 0" @click="updateAllReadStatus()">Mark All as Read</p>
    </div>
    <div class="max-h-96 overflow-y-auto">
      <template v-if="notifications.length > 0">
        <div class="border-t" v-for="(data, index) in notifications" :key="index">
          <div :class="`${data.read ? 'bg-white' : 'bg-yellow-100'} flex items-start p-5 gap-5 cursor-pointer mdmax:p-3`" @click="updateStatus(data)">
            <!-- <img :src="require(`@/assets/images/icons/userNone.png`)" class="rounded-full border-2 w-12 h-12" /> -->
            <div class="w-full mdmax:text-left">
              <p class="text-neutral-500 text-base font-semibold mb-2">
                {{ data.title }}
              </p>
              <p class="text-neutral-400 text-sm">
                {{ data.message }}
              </p>
              <p class="text-neutral-400 text-xs font-medium mt-2" v-if="data.createdDate">
                {{ formatDate(data.createdDate) }}
              </p>
            </div>
          </div>
        </div>
      </template>
      <template v-else> <div class="border-t flex justify-center items-center py-6 text-sm text-neutral-500">- Notification is empty -</div></template>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
  data() {
    return {
      documentCategories: ['PLATFORM_CENTER_COMMENT_OR_REPLACED_DOC', 'PLATFORM_CENTER_APPROVE_DOC', 'PLATFORM_CENTER_REJECT_DOC'],
      registrationCategories: ['ALLIANCE_REGISTRATION', 'PLATFORM_CENTER_CHANGE_ROLE'],
      statusMapping: {
        ACTIVE: 'active-clients',
        INACTIVE: 'archived-clients'
      }
    }
  },
  computed: {
    ...mapGetters('notification', ['notifications']),
    ...mapGetters('customer', ['customer']),
    userId() {
      return this.customer?.id
    }
  },
  methods: {
    ...mapActions('notification', ['updateSingleRead', 'updateAllRead']),
    formatDate(date) {
      return moment(date).local().format('DD MMM YYYY HH:mm')
    },
    updateAllReadStatus() {
      this.updateAllRead({
        userId: this.userId,
        params: {
          platform: 'ADMIN_CENTER'
        }
      }).then(() => {
        this.$emit('refetch')
      })
    },
    updateStatus(item) {
      if (!item.read) {
        this.updateSingleRead({
          userId: this.userId,
          notificationId: item.id
        }).then(() => {
          setTimeout(() => {
            this.$emit('refetch')
            this.handleRedirection(item)
          }, 500)
        })
        return
      }
      this.handleRedirection(item)
    },
    handleRedirection(item) {
      let targetPage = ''
      const metaInfo = JSON.parse(item.metainfo)
      if (this.documentCategories.indexOf(item.category) > -1) {
        const docType = encodeURIComponent(metaInfo.docType)
        targetPage = `/document/approve?is=${docType}&id=${metaInfo.commonId}`
      } else if (this.registrationCategories.indexOf(item.category) > -1) {
        const status = this.statusMapping[metaInfo.status]
        targetPage = `/profile`
      }

      if (this.$route.fullPath !== targetPage) this.$router.push(targetPage)
    }
  }
}
</script>
